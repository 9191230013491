import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      homeBanner: file(relativePath: { eq: "Banner.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" /> 
      <Helmet
        bodyAttributes={{
          class: "page-front",
        }}
      />
      <Row>
        <Col sm={12}>
          <div className="home-banner mb-4">
            <img
              className=""
              src={data.homeBanner.childImageSharp.fluid.src}
              alt="Home Banner"
            />
            <div>
              <h2>We're Committed to Excellence</h2>
              <p>
                In 1989, we decided to be "The Best You Can Get." <br />
                And it's still our motto today.
              </p>
              <Button href="/about-us" variant="primary">
                Learn More
              </Button>
            </div>
          </div>
          <div className="statements text-center mt-5">
            <div className="mx-3">
              <h2>Green Standards</h2>
              <p className="px-4">
                We incorporate environmental considerations into every phase of
                the building process. We are a LEEDS supplier.
              </p>
            </div>
            <div className="mx-3">
              <h2>Turnkey Framing</h2>
              <p className="px-4">
                Time is money. Our Turnkey Frame Construction cuts down on
                jobsite errors and omissions by working with you from the
                beginning.
              </p>
            </div>
            <div className="mx-3">
              <h2>Our Customers Say</h2>
              <p className="px-4">
                "The guys at Capital are VERY good and I have a lot of faith in
                their work. Real team players!!"
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage
